import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';

import { BrandAction } from '@hiptraveler/data-access/brand';
import { AppListenerService, getWindowRef, HT_FROM_WIDGET_SIGNOUT, promiseDelay, setClientCId, setClientVId } from '@hiptraveler/common';

@Injectable()
export class ExperienceFinderV2Service implements OnDestroy {

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private appListener: AppListenerService
  ) { }
  
  ngOnDestroy(): void {
    this.changeUiState(true);
  }

  get action(): string {
    return getWindowRef()?.location?.pathname?.split('/')?.reverse()[0] || '-';
  }

  async initialize(): Promise<void> {
    this.changeUiState(false);
    this.setClientDefaults();
    await this.getBrandInfoData();
  }
  
  setClientDefaults(): void {

    setClientCId(this.route.snapshot.params?.['cId'], false);

    const fromWidgetSignout = getWindowRef()?.localStorage?.getItem(HT_FROM_WIDGET_SIGNOUT);

    if (!fromWidgetSignout) {
      setClientVId(this.route.snapshot.params?.['vId']);
    }

    fromWidgetSignout && getWindowRef()?.localStorage?.removeItem(HT_FROM_WIDGET_SIGNOUT)
  }

  async getBrandInfoData(): Promise<void> {
    try {
      await firstValueFrom(this.store.dispatch(new BrandAction.GetBrandInfo));
    } finally { }
  }

  changeUiState(state: boolean): void {
    this.appListener.appSiteNavVisibility$$.next(state);
    this.appListener.appFooterVisibility$$.next(state);
    this.appListener.experienceFinderVisibility$$.next(state);
  }

  widgetDisplayStateListener(callback: (state: boolean) => void): void {
    getWindowRef()?.addEventListener('message', async (event: MessageEvent<{ message: string, value: string }>) => {

      if (event.data.message !== 'ht_exp_finder_modal_state' || !getWindowRef()) return;

      const state = !!+event.data.value;
      if (state) {await promiseDelay(150)}

      const cdk = getWindowRef()?.document.body.getElementsByClassName('cdk-overlay-container').item(0) as HTMLDivElement;
      cdk && (cdk.style.opacity = event.data.value);

      callback(state);
    });
  }

}
