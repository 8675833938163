import { Directive, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { firstValueFrom, fromEvent, takeUntil } from 'rxjs';

import { EFAStateServiceService, ScreenView } from '../../shared';

const DEFAULT_HEIGHT: number = 210;

@Directive({
  selector: '[heightResizer]'
})
export class HeightResizerDirective implements OnDestroy, AfterViewInit {

  resizeObserver: ResizeObserver;

  constructor(
    private element: ElementRef<HTMLDivElement>,
    private stateService: EFAStateServiceService
  ) { }

  ngOnDestroy(): void {
    this.resizeObserver.disconnect();
  }

  ngAfterViewInit(): void {

    const host = this.element.nativeElement.parentElement;
    const highlight = host?.firstElementChild as HTMLElement;
    const conversations = host?.lastElementChild as HTMLElement;

    this.resizeObserver = new ResizeObserver(async entries => {
      for (let entry of entries) {
        const newHeight = entry.contentRect.height;
        const highlightHeight = DEFAULT_HEIGHT - newHeight;

        const isMobile = await firstValueFrom(this.stateService.isMobile$);
        if (isMobile) return;

        if (highlightHeight <= 64) {
          host?.classList.add('scroll-view')
          this.conversationScrollBottom();
        } else {
          highlight.style.height = `${highlightHeight}px`;
          console.log('@@@ heightResizer ::', highlight.style.height);
        }

        if (highlightHeight <= 80) {
          Array.from(highlight.children).forEach(child => {
            const element = child as HTMLElement;
            element.style.justifyContent = 'center';
            (element.firstElementChild as HTMLElement).style.transform = 'translateY(0)';
          });
        }
      }
    });

    host && fromEvent<WheelEvent>(host, 'wheel').pipe(
      takeUntil(this.stateService.subscription$)
    ).subscribe(() => this.observeComputeHeight());

    this.stateService.conversations$.pipe(
      takeUntil(this.stateService.subscription$)
    ).subscribe(() => this.observeComputeHeight());

    conversations && this.resizeObserver.observe(conversations);
  }

  async observeComputeHeight(): Promise<void> {

    const isMobile = await firstValueFrom(this.stateService.isMobile$);
    if (this.stateService.screenViewValue$$.value !== ScreenView.welcome || isMobile) return;

    const host = this.element.nativeElement.parentElement!;
    const scrollHeight = Math.round(host.scrollHeight / 10) * 10;
    const scrollTop = Math.round((Math.floor(host.scrollTop) + host.clientHeight) / 10) * 10;
    const defaultScrollHeight = scrollHeight === host.clientHeight;

    console.log('@@@ COMPUTE-HEIGHT', 'SH', scrollHeight, 'HCH', host.clientHeight, 'ST', scrollTop, 'HST', host.scrollTop);

    if (defaultScrollHeight && !this.stateService.conversations$$.value.length) {
      this.stateService.allowScroll$$.next('allow');
      console.log('@@@ same', `allowScroll$$.next('allow')`);
    } else if (host.scrollTop <= 0) {
      this.stateService.allowScroll$$.next(defaultScrollHeight ? 'allow' : 'allow-up');
      console.log('@@@ up', `allowScroll$$.next('${defaultScrollHeight ? 'allow' : 'allow-up'}')`);
    } else if (scrollHeight === scrollTop) {
      this.stateService.allowScroll$$.next('allow-down');
      console.log('@@@ down', `allowScroll$$.next('allow-down')`);
    } else {
      this.stateService.allowScroll$$.next('disable');
      console.log('@@@ default', `allowScroll$$.next('disable')`);
    }
  }

  conversationScrollBottom(): void {
    const host = this.element.nativeElement.parentElement as HTMLElement;
    setTimeout(() => (host.scrollTop = host.scrollHeight));
  }

}
