import { AfterViewInit, Component, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { ExpFinderPanelAction, expFinderPanelAction } from '@hiptraveler/features/exp-finder-ai';
import { ExperienceFinderV2Service } from './experience-finder-v2.service';
import { getWindowRef } from '@hiptraveler/common';

@Component({
  selector: 'app-experience-finder-v2',
  template: `<ng-template #experienceFinder></ng-template>`,
  host: { class: 'page-experience-finder-ai' },
  viewProviders: [ ExperienceFinderV2Service ],
  encapsulation: ViewEncapsulation.None
})
export class ExperienceFinderV2Component implements OnInit, AfterViewInit {

  @ViewChild('experienceFinder', { read: ViewContainerRef }) experienceFinderRef: ViewContainerRef;

  panelAction: ExpFinderPanelAction;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private service: ExperienceFinderV2Service
  ) { }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      this.experienceFinderPanelDisplay(false);
    }
    if (event.code === 'Space') {
      this.experienceFinderPanelDisplay(true)
    }
  }

  ngOnInit(): void {
    this.service.initialize();
    this.service.widgetDisplayStateListener((state: boolean) => {
      this.experienceFinderPanelDisplay(state);
    })
  }
  
  ngAfterViewInit(): void {
    this.panelAction = expFinderPanelAction();
    this.experienceFinderPanelDisplay(true);
  }
  
  experienceFinderPanelDisplay(state: boolean): void {
    if (!isPlatformBrowser(this.platformId)) return;

    state || getWindowRef()?.top?.postMessage({ message: 'ht_exp_finder_modal_close' }, '*');

    state
      ? this.panelAction?.openExperienceFinderPanel(this.experienceFinderRef, this.service.action)
      : this.panelAction?.closeExperienceFinder();
  }

}
