import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { ChatCompletionsService } from '@hiptraveler/core/openai';
import { CarouselService, CarouselView, EFAStateServiceService, ScreenView } from '../../shared';

@Component({
  selector: 'carousel-location',
  template: `<poi-select-carousel selectedFilter="location"></poi-select-carousel>`,
  host: { class: 'experience-finder-ai--host-carousel-location experience-finder-ai--host-element experience-finder-ai--host-carousel' },
  viewProviders: [ ChatCompletionsService, CarouselService ],
  encapsulation: ViewEncapsulation.None
})
export class CarouselLocationComponent extends CarouselView implements OnInit {

  constructor(
    completions: ChatCompletionsService,
    stateService: EFAStateServiceService,
    carousel: CarouselService
  ) {
    super(completions, stateService, carousel);
  }

  ngOnInit(): void {
    this.initialize(ScreenView.locationCarousel, 'location');
  }

}
