import { Component, ElementRef, HostListener, AfterViewInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { InputControlService } from './input-control.service';
import { EFAStateServiceService, ScreenView } from '../../shared';
import { opacityFade } from '@hiptraveler/animations';

@Component({
  selector: 'input-control',
  templateUrl: './input-control.component.html',
  styleUrls: ['./styles/input-control.component.scss'],
  host: { class: 'experience-finder-ai--host-input-control disabled' },
  viewProviders: [ InputControlService ],
  encapsulation: ViewEncapsulation.None,
  animations: [ opacityFade(150) ]
})
export class InputControlComponent implements AfterViewInit {

  @ViewChild('inputField') inputField: ElementRef<HTMLInputElement>;

  contentInput: string = '';
  typingTimer: any;
  allowPreviewNavigation: boolean = false;

  constructor(
    public service: InputControlService,
    public stateService: EFAStateServiceService
  ) { }

  private get screenView(): ScreenView {
    return this.stateService.screenViewValue$$.value || ScreenView.welcome;
  }

  ngAfterViewInit(): void {
    this.service.observeRequest();
    this.service.screenChangesObserver();
    this.allowPreviewNavigation = false;
    setTimeout(() => (this.allowPreviewNavigation = true), 2000);
  }

  @HostListener('mouseenter', ['$event'])
  async onMouseEnter(): Promise<void> {
    const isMobile = await firstValueFrom(this.stateService.isMobile$);

    if (this.stateService.scrolling$$.value || isMobile || !this.allowPreviewNavigation) return;
    if (this.screenView === ScreenView.welcome) {
      setTimeout(() => this.stateService.scrollToScreen$$.next(this.stateService.view.screen2), 500);
    }
  }

  onInput(): void {
    this.stateService.pauseBrandInfoUXState$$.next(false);
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => this.stateService.pauseBrandInfoUXState$$.next(true), 1200);
  }

  sendMessage(): void {

    if (!this.contentInput || this.stateService.inputPending$$.value) return;

    this.stateService.inputMessage$$.next(this.contentInput);
    this.stateService.appendConversation({ message: this.contentInput, sender: true });
    this.resetInputState();
  }

  submitMessage(): void {

    if (this.stateService.inputPending$$.value || this.screenView !== ScreenView.summary) return;
    this.stateService.formSubmit$.next();
    this.resetInputState();
  }

  async nextScreen(): Promise<void> {
    const isMobile = await firstValueFrom(this.stateService.isMobile$);
    const screenViewValue = this.stateService.screenViewValue$$.value || ScreenView.welcome;
    if ([ ScreenView.mainSelection, ScreenView.summary ].includes(screenViewValue) || isMobile) return;
    this.stateService.scrollToView$$.next('next');
  }

  resetInputState(): void {
    this.contentInput = '';
    this.inputField.nativeElement.value = this.contentInput;
    this.stateService.inputMessage$$.next(this.contentInput);
  }

}
