import * as moment from 'moment';

import { AddItineraryDto, BrandInfo } from '@hiptraveler/data-access/api';
import { brandCampaignId, clientVID, currentLang, isWidget } from '@hiptraveler/common';
import { FormValue } from '../shared';

function formatDateToMMDDYYYY(date: Date): string {
  return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()}`;
}

function setItineraryName(numDays: number, location: string) {
  let itiName = '';

  if (numDays === 1) {
    itiName = 'One day in ';
  } else if (numDays % 7 === 0) {
    const num = numDays / 7;
    itiName = num > 1 ? `${num} weeks in ` : `${num} week in `;
  } else {
    itiName = `${numDays} days in `;
  }

  return itiName + location;
}

interface RequestPayload {
  formValue: FormValue;
  brandState: Partial<BrandInfo> | null;
  authenticated: boolean;
}

export function getRequestPayload(value: RequestPayload): AddItineraryDto {

  const { carouselData, dateRange, clientInfo } = value.formValue;
  const start = moment(dateRange.start);
  const end = moment(dateRange.end);
  const startDate = dateRange.start ? formatDateToMMDDYYYY(start.toDate()) : '';
  const endDate = dateRange.end ? formatDateToMMDDYYYY(end.toDate()) : '';
  const numDays = end.diff(start, 'days') || 1;

  const locations = value?.brandState?.experiences?.filter(e => carouselData.screen6.includes(e?.code || '')) || [];
  const location = locations[0];

  const payload: AddItineraryDto = {
    lang: currentLang(),
    campgnReference: brandCampaignId(),
    name: setItineraryName(numDays, location?.name || ''),
    experience: carouselData.screen3.toString(),
    travelStyle: carouselData.screen4.toString(),
    activities: carouselData.screen5.toString(),
    startDate, endDate, numDays, firstLoc: location?.name || '',
    locMap: locations.map(location => ({
      id: location.id || '',
      formattedAddr: location.name || '',
      type: location.name || ''
    })),
    paxes: {
      numAdults: clientInfo.adults,
      numChild: clientInfo.children,
      infants: clientInfo.infants
    },
    customIti: 'auto',
    travelogue: false,
    hasItinerary: true
  };

  if (isWidget()) {
    payload.route = 'widget';
    payload.vId = clientVID();
  } else {
    payload.vId = value.authenticated ? clientVID() : (clientVID() || clientVID({ create: true }));
  }

  return payload;
}
