import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Directive, Inject, PLATFORM_ID } from '@angular/core';
import { auditTime, debounceTime, distinctUntilChanged, firstValueFrom, takeUntil } from 'rxjs';
import Typed from 'typed.js';

import { EFAStateServiceService, ScreenView } from '../../shared';
import { getWindowRef } from '@hiptraveler/common';

@Directive({
  selector: '[textTyped]'
})
export class TextTypedDirective implements AfterViewInit {

  typed?: Typed;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private stateService: EFAStateServiceService
  ) { }

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) return;

    this.typedTextSetup();
    this.brandHeaderTypeState();

    this.stateService.screenViewValue$.pipe(
      debounceTime(350),
      distinctUntilChanged(),
      takeUntil(this.stateService.subscription$)
    ).subscribe(this.typedTextSetup.bind(this));
  }

  async typedTextSetup(screen: ScreenView = ScreenView.mainSelection): Promise<void> {

    const summaryScreenState = await firstValueFrom(this.stateService.summaryItems$);

    const value = {
      summaryScreenState: !!summaryScreenState?.length
    };
    
    this.typed = new Typed('#brand-header-highlight', {
      strings: [ getHighlightTextByScreen(screen, value) ],
      typeSpeed: 15,
      showCursor: false,
      startDelay: 300,
    });
  }

  brandHeaderTypeState(): void {
    this.stateService.brandHeaderTypeState$$.asObservable().pipe(
      auditTime(1000)
    ).subscribe((state: boolean) => {
      if (state) {
        this.typed?.start();
      } else {
        this.typed?.destroy();
      }
    });
  }

}

function getHighlightTextByScreen(screen: ScreenView, value: { summaryScreenState: boolean }): string {

  const isMobile = getWindowRef()?.innerWidth <= 600;

  const locationCarousel = 'Where are you headed, Traveler?';
  const calendar = 'When would you like to hit the road?';
  const clientInfo = 'How many travelers for the trip?';
  const summary = value.summaryScreenState
    ? 'Looks like an amazing trip! Here\'s a summary of your preferences:'
    : 'Start planning your amazing trip!';

  if (screen === ScreenView.experienceCarousel) {
    return isMobile ? locationCarousel : 'Whats your Experience Style?';
  }
  if (screen === ScreenView.travelCarousel) {
    return isMobile ? calendar : 'Whats your Travel Style?';
  }
  if (screen === ScreenView.activitiesCarousel) {
    return isMobile ? clientInfo : 'What are your favorite Activities?';
  }
  if (screen === ScreenView.locationCarousel) {
    return isMobile ? summary : locationCarousel;
  }
  if (screen === ScreenView.calendar) {
    return calendar;
  }
  if (screen === ScreenView.clientInfo) {
    return clientInfo;
  }
  if (screen === ScreenView.summary) {
    return summary;
  }
  return isMobile ? 'Let\'s plan your perfect trip!' : 'How can I help you today?';
}
