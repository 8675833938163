
<profile-selection                                      
  divider className="experience-finder-ai-profile-menu-panel"
  *ngIf="profileSelectionVisibility">
</profile-selection>                                    <!-- (Component) Profile menu dropdown UI -->

<loader-screen></loader-screen>                         <!-- (Component) HipTraveler Brand Logo UI/UX -->

<!-- Content -->

<div class="experience-finder-ai--host"
  hostObserver hostScroll hostElement #host
  [ngClass]="{ mobile: stateService.isMobile$ | async}">

  <ng-template #brandHeader></ng-template>              <!-- (Component) Header UI -->
  <ng-template #inputControl></ng-template>             <!-- (Component) Input field -->

  <!-- Views -->

  <ng-container *ngIf="stateService.isDesktop$ | async">
    <ng-template #welcomeView></ng-template>            <!-- Desktop Screen 1 -->
  </ng-container>

  <ng-template #mainSelection></ng-template>            <!-- Desktop Screen 2 -->   <!-- Mobile Screen 1 -->

  <ng-container *ngIf="stateService.isMobile$ | async">
    <ng-template #carouselGridView></ng-template>                                   <!-- Mobile Screen 2 -->
  </ng-container>

  <ng-container *ngIf="stateService.isDesktop$ | async">
    <ng-template #carouselExperience></ng-template>     <!-- Desktop Screen 3 -->
    <ng-template #carouselTravel></ng-template>         <!-- Desktop Screen 4 -->
    <ng-template #carouselActivities></ng-template>     <!-- Desktop Screen 5 -->
  </ng-container>

  <ng-template #carouselLocation></ng-template>         <!-- Desktop Screen 6 -->   <!-- Mobile Screen 3 -->
  <ng-template #calendar></ng-template>                 <!-- Desktop Screen 7 -->   <!-- Mobile Screen 4 -->
  <ng-template #clientInfo></ng-template>               <!-- Desktop Screen 8 -->   <!-- Mobile Screen 5 -->
  <ng-template #summary></ng-template>                  <!-- Desktop Screen 9 -->   <!-- Mobile Screen 6 -->

</div>
